import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { ShareService } from '@Services/share/share.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnInit {
  categories = ['recambios', 'planos', 'certificados', 'esquemas', 'manuales'];
  model = '';
  faSearch = faSearch;
  searchValue = '';

  constructor(private shareService: ShareService, route: Router) {
    route.events.subscribe((event) => {
      this.searchValue = '';
    });
  }

  ngOnInit(): void {}

  applyFilter(event: Event) {
    this.searchValue = (event.target as HTMLInputElement).value;
    this.shareService.changeSearch(this.searchValue.trim().toLowerCase());
  }
}
