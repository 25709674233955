import { Component, OnDestroy, OnInit } from '@angular/core';
import { CartService } from '@Services/cart/cart.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-cart-button',
  templateUrl: './cart-button.component.html',
  styleUrls: ['./cart-button.component.scss']
})
export class CartButtonComponent implements OnInit, OnDestroy {

  cart: boolean = false;
  cartNumber!: number;

  isCart: Subscription = this.cartService.currentCart
  .subscribe((cart:any) => {
    if(cart.length == 0) {
      cart = false;
      this.cartNumber = 0;
      return;
    }
    this.cart = true;
    this.cartNumber = cart.length;
  })
  constructor(
    private cartService: CartService
  ) { }
  ngOnDestroy(): void {
    this.isCart.unsubscribe();
  }

  ngOnInit(): void {
  }
}
