
<section>
  <ngx-extended-pdf-viewer
  *ngIf="plano"
  [src]="plano"

  [useBrowserLocale]="true"
  [textLayer]="true"
  [showHandToolButton]="true"
  > <!-- enable text selection -->
  </ngx-extended-pdf-viewer>
</section>
<section mat-dialog-actions class="btn-sec">
  <button mat-flat-button class="btn-cancel" (click)="onNoClick()">Cancelar</button>
</section>
