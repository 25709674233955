<div class="sep">
  <div *ngFor="let file of list" class="block">
    <div class="item1">
      <img [src]="file.img" alt="file.img" class="img-ban">
    </div>

    <div class="item2">
      <p><strong [innerHTML]="file.title | translate "></strong></p>
      <p [innerHTML]="file.subtitle | translate "></p>
    </div>
  </div>
</div>
<!-- <div class="img-plan">
  <img class="img-plan-img" src="assets/images/thumbnail_Logos_Plan_de_Recuperacion.jpg">
</div> -->
