<div class="header header-row">
  <div class="header-column-left">{{ 'sub-title' | translate }}</div>
  <div class="header-column-right">
    <nav class="header-row tex-grey">
      <ul>
        <!-- <li routerLink="">Ofertas</li>
        <li routerLink="">Los más vendidos</li> -->
        <li routerLink="att">{{'Atención al cliente' | translate}}</li>
        <!-- <li routerLink="faq">FAQ</li> -->
        <!-- <li>Track de pedido</li> -->
      </ul>
      <div class="separator-v"></div>
      <ul class="icons">
        <li>
          <select name="currency">
            <option value="eur">EUR</option>
            <!-- <option value="usd">USD</option> -->
          </select>
        </li>
        <li class="icons">
          <img src="assets/images/Mundo1.svg" class="icon-lang">
          <mat-select class="select-h" (selectionChange)="useLanguage($event.value)">
            <mat-option value="es">ESP</mat-option>
            <mat-option value="en">ENG</mat-option>
          </mat-select>
        </li>
      </ul>
      <div class="separator-v"></div>
      <ul class="social">
        <!-- <li class="no-dist"><a class="icon-circle icon-circle-fa"><fa-icon [icon]="faFacebook"class="icon"></fa-icon></a></li> -->
        <!-- <li class="no-dist"><a class="icon-circle icon-circle-tw"><fa-icon [icon]="faTwitter"class="icon"></fa-icon></a></li> -->
        <li class="no-dist"><a href="https://www.linkedin.com/showcase/comanai-spare-parts/" target="_blank"
            class="icon-circle icon-circle-li"><fa-icon [icon]="faLinkedin" class="icon"></fa-icon></a></li>
      </ul>
    </nav>
  </div>
</div>
