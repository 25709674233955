import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { environment } from '@environment/environment';
import { SnackBarComponent } from '@components/snack-bar/snack-bar.component';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CartService {
  private cart = new BehaviorSubject<any[]>([]);
  currentCart = this.cart.asObservable();
  cartExistent: any[] = [];
  isInCart: boolean = false;

  cartEndpoint: string = environment.api;

  constructor(
    private http: HttpClient,
    public router: Router,
    private snackbar: MatSnackBar
  ) {}

  setCart(data: any) {
    return this.http.post<any>(this.cartEndpoint + '/setCart', data);
  }

  changeCart(item: any, img: string, num: number) {
    const data = { item, img, num };

    if (item.station) {
      this.changeCartNotExistentElement(data);
      return;
    }

    const cartValue = this.cart.getValue();
    if (cartValue.length === 0) {
      this.cart.next([data]);
      this.snack('El producto ha sido añadido al carrito');
      return;
    }

    const elementExist = cartValue.find((element: any) => {
      return element.item.reference === data.item.reference && element.item.id === data.item.id;
    });

    if (elementExist) {
      this.snack('El producto ya está en su carrito');
      return;
    }

    this.changeCartNotExistentElement(data);
  }

  changeCartNotExistentElement(data: any) {
    console.log('no existe');
    this.cart.next(this.cart.getValue().concat([data]));
    this.snack('El producto ha sido añadido al carrito');
  }

  setNewCart(item: any) {
    this.cart.next(item);
    console.log('cart', item, this.cart);
  }

  /**
   *
   * @param data
   * @returns
   */
  setOrder(data: any) {
    return this.http.post<any>(this.cartEndpoint + '/orders', data);
  }


  /**
   *
   * @param id
   * @returns
   */
  getOrders(id: string) {
    return this.http.get<any>(this.cartEndpoint + '/orders/user');
  }

  /**
   *
   * @param id
   * @returns
   */
  getOrder(id: string) {
    return this.http.get<any>(this.cartEndpoint + '/orders/client/' + id);
  }


  getTransportPrice(country: number, zipcode: number, weight: number){
    const weigthInt = Math.ceil(weight);
    const uri = this.cartEndpoint + '/prices/' + country + '/' + zipcode + '/' + weigthInt;
    return this.http.get<number>(uri);
  }

  snack(msg: string) {
    this.snackbar.openFromComponent(SnackBarComponent, {
      duration: 700,
      announcementMessage: msg,
      horizontalPosition: 'right',
      verticalPosition: 'top',
      panelClass: 'okClass'
    });
  }
}
