import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ShareService } from '@Services/share/share.service';
import { Subscription } from 'rxjs';
import { API_ENDPOINTS } from "@constants/api-endpoints";
import { COMANAI_EXCERPTS } from "@constants/comanai-excerpts";

@Component({
  selector: 'app-buttons',
  templateUrl: './buttons.component.html',
  styleUrls: ['./buttons.component.scss'],
})
export class ButtonsComponent implements OnInit, OnDestroy {
  @Input() user: any;

  logo: string = '';
  id: string = '';
  files = API_ENDPOINTS.filesLogo;
  webAdminComanai = COMANAI_EXCERPTS.webAdminComanai;
  subscriptionUser: Subscription = this.shareService.currentUser.subscribe(
    (arg: any) => {
      console.log('subscrioibe en header', arg);
      this.user = arg;
      this.id = arg.id;
      this.user.uuid
        ? (this.logo = this.files + this.user.uuid)
        : (this.logo = '');
    }
  );

  constructor(private route: Router, private shareService: ShareService) {}

  ngOnInit(): void {}

  changeLang(lang: string): void {
    console.log(localStorage.getItem('language'));
  }

  goToCart(): void {
    console.log('go cart');
    this.route.navigate(['cart']);
  }

  ngOnDestroy() {
    this.logo = '';
    this.subscriptionUser.unsubscribe();
  }
}
