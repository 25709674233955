import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { User } from '@interfaces/user';
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from '@environment/environment';
import { UserService } from '@Services/user/user.service';
import { ShareService } from '@Services/share/share.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackBarErrorComponent } from '@components/snack-bar-error/snack-bar-error.component';
import { CartService } from '@Services/cart/cart.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  endpoint: string = environment.api;
  headers = new HttpHeaders().set('Content-Type', 'application/json');
  currentUser = {};
  logo: string = '';

  constructor(
    private http: HttpClient,
    public router: Router,
    private userService: UserService,
    private shareService: ShareService,
    private cartService: CartService,
    private snackbar: MatSnackBar
  ) {}

  /**
   * Register User
   * @param user
   * @returns
   */
  register(user: any) {
    return this.http.post<any>(this.endpoint + '/users/register', user);
  }

  // Sign-in login, return token to localStorage and set current user
  login(user: User, cartPhase: string) {
    return this.http.post<any>(`${this.endpoint}/login`, user).subscribe(
      (res: any) => {
        console.log('res', res);
        localStorage.setItem('access_token', res.result.token);
        localStorage.setItem('id', res.result.user.id);
        res.result.user.access_token = res.result.token;
        this.shareService.changeCurrentUser(res.result.user);
        console.log('isLogged');
        if (cartPhase === 'checkout') this.router.navigate(['cart/checkout']);
        if (cartPhase === 'login') this.router.navigate(['user']);
      },
      (error) => {
        console.log(error);
        return this.handleError(error);
      }
    );
  }

  getToken() {
    return localStorage.getItem('access_token');
  }

  get isLoggedIn(): boolean {
    let authToken = localStorage.getItem('access_token');
    return authToken != null ? true : false;
  }

  // Log Out, remove access JWT token
  doLogout() {
    localStorage.removeItem('access_token');
    localStorage.removeItem('hashCart');
    this.userService.changeLogo('');
    this.shareService.changeCurrentUser('');
    this.shareService.doLogOut();
    this.cartService.setNewCart('');
    let removeTokenId = localStorage.removeItem('id');
    if (removeTokenId == null) {
      this.router.navigate(['auth']);
    }
    this.router.navigate(['auth']);
  }
  // Set email to API, then return contact email with token
  forgot(data:any) {
    console.log('forgot', data);
    return this.http.post<any>(this.endpoint + '/users/forgot', data);
  }

  // Reset password. Send to API recover token
  resetPassword(pass: any) {
    return this.http.post<any>(this.endpoint + '/users/reset', pass);
  }

  handleError(error: HttpErrorResponse) {
    let msg = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      msg = error.statusText;

      this.snackbar.openFromComponent(SnackBarErrorComponent, {
        duration: 1000,
        announcementMessage: msg,
        horizontalPosition: 'right',
        verticalPosition: 'top',
        panelClass: 'errorClass',
      });
    } else {
      // server-side error
      msg = error.statusText;
      this.snackbar.openFromComponent(SnackBarErrorComponent, {
        duration: 1000,
        announcementMessage: msg,
        horizontalPosition: 'center',
        verticalPosition: 'bottom',
        panelClass: 'errorClass',
      });
    }
    return throwError(msg);
  }
}
