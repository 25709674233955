import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { ShareService } from '@Services/share/share.service';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    public authService: AuthService,
    public shareService: ShareService,
    public router: Router
  ) {}

  // Let access to the router if the user has jwt token, if not navigate to ''
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const authSubscribe = this.shareService.currentUser.subscribe(
      (arg: any) => {
        let authToken = arg.access_token;
        console.log('user ', arg);
        return authToken != null ? true : this.router.navigate(['auth']);
      }
    );

    //   if (this.authService.isLoggedIn !== true) {
    //   // window.alert("Access not allowed!");
    //   this.router.navigate(['auth'])
    // }
    authSubscribe.unsubscribe();
    return true;
  }
}
