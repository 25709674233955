<div class="page-template">
<div class="title-sec">
  <p class="title">{{ 'Preguntas Frecuentes' | translate }}</p>
</div>
<mat-expansion-panel *ngFor="let faq of faqs" >
  <mat-expansion-panel-header class="faq-xpand">
    <mat-panel-title [innerHTML]="'FAQs.' + faq.title | translate" class="faq-title">
    </mat-panel-title>
  </mat-expansion-panel-header>
  <p class="faq-res" [innerHTML]="'FAQs.' + faq.response | translate"></p>
</mat-expansion-panel>

</div>
