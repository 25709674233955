import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit, SimpleChanges
} from '@angular/core';
import { Router } from '@angular/router';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { AuthService } from '@Services/auth/auth.service';
import { ShareService } from '@Services/share/share.service';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss'],
})
export class NavBarComponent implements OnInit, OnChanges {
  @Input() user: string = '';


  links = [
    { text: 'Recambios más vendidos', link: '' },
    // { text: 'Ofertas', link: 'ofertas'},
    { text: 'Atención al cliente', link: 'att' },
  ];

  linksLogged = [
    { text: 'proyectos', link: 'user' },
    { text: 'comerciales', link: '' },
    // { text: 'documentación', link: 'att'},
    { text: 'atención al cliente', link: 'att' },
  ];
  faArrowRight = faArrowRight;
  isLogged = false;

  constructor(
    private router: Router,
    private authService: AuthService,
    private shareService: ShareService
  ) {
    console.log('current user', this.user);
  }

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    console.log('change', this.user);
    this.changeNav(changes.user.currentValue);
  }

  changeNav(user: any) {
    if (this.user) this.isLogged = true;
  }

  setLoggedIn() {
    console.log('logged', this.user, this.isLogged);
    this.user !== '' ? (this.isLogged = true) : (this.isLogged = false);
  }

  linkLogin(): void {
    console.log('router');
    this.router.navigate(['auth']);
  }
  doLogOut(): void {
    this.isLogged = false;
    this.authService.doLogout();
  }
  goToLink(link: string): void {
    this.router.navigate([link]);
  }
}
