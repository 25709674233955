<nav class="nav">
  <div class="content-nav">
    <ul class="ul-nav" *ngIf="isLogged; else isNotLogged">
      <li
        *ngFor="let link of linksLogged"
        class="list"
        routerLinkActive="list-active"
        [routerLink]="[link.link]"
        [routerLinkActiveOptions]="{ exact: true }"

        [innerHTML]="link.text | translate"
      >
      </li>
    </ul>
    <ng-template #isNotLogged>
      <ul class="ul-nav">
        <li class="list" [routerLink]="'/'">
      {{ 'Inicie sesión por favor' | translate }}
      </li>
      </ul>
      <!-- <ul class="ul-nav">
        <li
          *ngFor="let link of links; let i = index"
          class="list"
          routerLinkActive="list-active"
          [routerLink]="[link.link]"
          [routerLinkActiveOptions]="{ exact: true }"
          ariaCurrentWhenActive=""
        >
          {{ link.text | translate }}
        </li>
      </ul> -->
    </ng-template>
  </div>
  <button
    *ngIf="isLogged; else logIn"
    (click)="doLogOut()"
    class="btn btn-log-out"
  >
    {{'CERRAR SESIÓN' | translate }}&nbsp;&nbsp;&nbsp;<fa-icon
      [icon]="faArrowRight"
      class="arrow"
    ></fa-icon>
  </button>
  <ng-template #logIn>
    <button (click)="linkLogin()" class="btn btn-link">
      {{'INICIAR SESIÓN' | translate }}&nbsp;&nbsp;&nbsp;<fa-icon
        [icon]="faArrowRight"
        class="arrow"
      ></fa-icon>
    </button>
  </ng-template>
</nav>
