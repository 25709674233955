<div class="flex">
  <div class="content">
    <img [src]="logoComanai" alt="SPARE PARTS COMANAI" class="logo">
  </div>
  <div class="content">
    <ul>
      <li><span class="li-title">{{ 'atCliente' | translate | uppercase }}</span></li>
      <li class="li-subtitle links" routerLink="faq">{{ 'preguntasFrecuentes' | translate | uppercase }}</li>
      <!-- <li class="li-subtitle">{{ 'sugerencias' | translate | uppercase }}</li> -->
    </ul>

  </div>
  <div class="content">
    <ul>
    <li><span class="li-title">{{ 'infoContact' | translate }}</span></li>
    <li class="li-subtitle">
      {{ 'direccion' | translate | uppercase }}<br>
      {{ 'dir1' | translate }}<br>
      {{ 'dir2' | translate }}
    </li>
    <li class="li-subtitle">
      <span >{{ 'telefono' | translate | uppercase }}</span><br>
      {{ 'tel' | translate | uppercase }}
    </li>
    <li class="li-subtitle">
      <span class="li-subtitle">{{ 'email' | translate | uppercase}}</span><br>
      {{ 'emailEs' | translate }}
    </li>
    <li></li>
  </ul>
  </div>
  <div class="content">
    <ul>
    <li><span class="li-title">{{ 'avLegal' | translate | uppercase }}</span></li>
    <li class="li-subtitle">{{ 'aLegal' | translate }}</li>
    <li class="li-subtitle">{{ 'politicaDePrivacidad' | translate }}</li>
    <li class="li-subtitle">{{ 'terminosYCondiciones' | translate }}</li>
    <li class="li-subtitle">{{ 'politicaDeCookies' | translate }}</li>
  </ul>
  </div>
</div>
