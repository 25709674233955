import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from '@environment/environment';
import { BehaviorSubject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { VisorComponent } from '@components/visor/visor.component';
import { saveAs } from 'file-saver';
import { VideoComponent } from '@components/video/video.component';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  endpoint: string = environment.api;
  comercial: any = '';
  logo: string = '';

  private comercialSource = new BehaviorSubject<any>(this.comercial);
  private logoSource = new BehaviorSubject<any>(this.logo);

  currentComercial = this.comercialSource.asObservable();
  currentLogo = this.logoSource.asObservable();

  token = localStorage.getItem('access_token');
  headers = new HttpHeaders({
    Authorization: 'Bearer ' + this.token,
    'Content-Type': 'application/json',
  });

  constructor(
    private http: HttpClient,
    public router: Router,
    public dialog: MatDialog
  ) {}

  getLastCart(id: string) {
    return this.http.get<any>(this.endpoint + '/getLastCart/' + id);
  }
  getCommercial(id: string) {
    return this.http.get<any>(this.endpoint + '/getCommercial/' + id);
  }

  changeComercial(comercial: any) {
    console.log('commercial service', comercial);
    this.comercialSource.next(comercial);
  }

  changeLogo(logo: any) {
    this.logoSource.next(logo);
  }

  getUserAddress(id: string) {
    return this.http.get<any>(this.endpoint + '/getUserAddress/' + id);
  }

  commercials() {
    return this.http.get<any>(this.endpoint + '/commercials');
  }

  watchPlan(draw: string) {
    const headers = this.headers;
    this.http
      .get(draw, { headers, responseType: 'blob' })
      .subscribe((response) => {
        const blob: Blob = response;
        const data = {
          plano: blob,
        };
        const dialogRef = this.dialog.open(VisorComponent, {
          data,
          width: '100vw',
          height: '100vh',
        });
      });
  }

  downloadDraw(draw: string, filename: string) {
    const headers = this.headers;
    this.http
      .get(draw, { headers, responseType: 'blob' })
      .subscribe((response) => {
        const blob: Blob = response;
        saveAs(blob, filename);
      });
  }

  watchVideo(video: string) {
    const data = {video : video};
    const dialogRef = this.dialog.open(VideoComponent, {
          data,
          width: '100vw'
        });
  }
}
