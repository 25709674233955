import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { Router } from '@angular/router';
import {
  faFacebookF,
  faLinkedinIn,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons';
import { faBars, faUser } from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '@Services/auth/auth.service';
import { Location } from '@angular/common';
import { ShareService } from '@Services/share/share.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnChanges {
  @Input() user: string = '';

  userSubscription = this.shareService.currentUser.subscribe((data) => {
    data == ''? this.isLogged == false: this.isLogged == true;
    this.user = data;
    console.log('HEADER ', data, this.isLogged);
  });

  filter: string = '';
  bars = faBars;
  userLogo = faUser;
  faFacebook = faFacebookF;
  faYt = faYoutube;
  faLinkedin = faLinkedinIn;

  links = [
    { text: 'Recambios más vendidos', link: '' },
    // { text: 'Ofertas', link: 'ofertas'},
    { text: 'Atención al cliente', link: 'att' },
  ];

  linksLogged = [
    { text: 'proyectos', link: 'user' },
    { text: 'comerciales', link: '' },
    // { text: 'documentación', link: 'att'},
    { text: 'atención al cliente', link: 'att' },
  ];

  isLogged = false;
  lang: string = 'es';

  constructor(
    private translate: TranslateService,
    private router: Router,
    private authService: AuthService,
    private shareService: ShareService,
    private location: Location
  ) {
    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang('es');

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    translate.use('es');
    localStorage.setItem('language', 'en');
  }

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    console.log('change', this.user);
    this.changeNav(this.user);
  }

  // Change language by click
  useLanguage(value: any): any {
    console.log('entra', value.value);
    const langId = value.value;
    const local = this.location.path();
    this.translate.use(langId);
    localStorage.setItem('language', langId);
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([local]);
    });
  }

  changeNav(user: any) {
    user? this.isLogged = true: this.isLogged = false;
  }

  goToLink(link: string): void {
    this.router.navigate([link]);
  }
  doLogOut(): void {
    this.isLogged = false;
    this.authService.doLogout();
  }
}
