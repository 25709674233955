<div class="page-template">
  <div class="container">
    <ul class="breadcrumb">
      <li>
        <span
          class="li-link"
          [ngClass]="{ activePhase: cartPhase === 'setCart' }"
          (click)="setCart()"
          >{{ "Carrito de compra" | translate }}</span
        ><fa-icon [icon]="faChevronRight" class="icon-chev"></fa-icon>
      </li>
      <li *ngIf="!isLogged">
        <span
          class="li-link"
          (click)="goToLogin()"
          [ngClass]="{ activePhase: cartPhase === 'isNotLogged' }"
          >{{ "Cuenta Spare Parts" | translate }}</span
        ><fa-icon [icon]="faChevronRight" class="icon-chev"></fa-icon>
      </li>
      <li>
        <span
          class="li-link"
          (click)="checkOut()"
          [ngClass]="{ activePhase: cartPhase === 'isCheckout' }"
          >{{ "Checkout" | translate }}</span
        ><fa-icon [icon]="faChevronRight" class="icon-chev"></fa-icon>
      </li>
      <li>
        <span
          class="li-link"
          (click)="cartComplete()"
          [ngClass]="{ activePhase: cartPhase === 'isCompleted' }"
          >{{ "Pedido completado" | translate }}</span
        >
      </li>
    </ul>
  </div>
  <router-outlet></router-outlet>
</div>
