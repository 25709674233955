import { HttpClient } from '@angular/common/http';
import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';

@Pipe({
  name: 'multiSecure'
})
export class MultiSecurePipe implements PipeTransform {
  constructor(private http: HttpClient) {}

  transform(value:any, args:any[]): unknown {
    const url = value + args;
    console.log('url ', value, args);
    return new Observable<string>((observer) => {
      observer.next(
        'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=='
      );
      const token = localStorage.getItem('access_token');
      this.http
        .get(url, {
          responseType: 'blob',
          headers: { Authorization: token ? 'Bearer ' + token : '' },
        })
        .subscribe((response) => {
          const reader = new FileReader();
          reader.readAsDataURL(response);
          reader.onloadend = function () {
            observer.next((reader as any).result);
          };
        });

      return { unsubscribe() {} };
    });  }

}
