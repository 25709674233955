import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PagesComponent } from './pages/pages.component';
import { FooterComponent } from '@components/footer/footer.component';
import { HeaderComponent } from '@components/header/header.component';
import { BottomFooterComponent } from '@components/bottom-footer/bottom-footer.component';
import { LogoComponent } from '@components/header/logo/logo.component';
import { SearchComponent } from '@components/header/search/search.component';
import { ButtonsComponent } from '@components/header/buttons/buttons.component';
import { HeaderTopComponent } from '@components/header-top/header-top.component';
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoginComponent } from './login/login.component';
import { AuthInterceptor } from '@Services/auth/auth.interceptor';
import { NavBarComponent } from '@components/nav-bar/nav-bar.component';
import { PagesUserComponent } from './pages-user/pages-user.component';
import { CartButtonComponent } from '@components/header/cart-button/cart-button.component';
import { CartComponent } from './cart/cart.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FooterBannerComponent } from '@components/footer-banner/footer-banner.component';
import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es';
import { SnackBarComponent } from '@components/snack-bar/snack-bar.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { AttClienteComponent } from './att-cliente/att-cliente.component';
import { MatMenuModule } from '@angular/material/menu';
import { FaqComponent } from './faq/faq.component';
import { MatSelectModule } from '@angular/material/select';
import { VisorComponent } from '@components/visor/visor.component';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { MatDialogModule } from '@angular/material/dialog';
import { LoginCheckoutComponent } from './login/login-checkout/login-checkout.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { PipesModule } from 'app/shared/pipes/pipes.module';
import { SnackBarErrorComponent } from '@components/snack-bar-error/snack-bar-error.component';
import { VideoComponent } from './shared/components/video/video.component';
import { TutorialComponent } from './shared/components/tutorial/tutorial.component';
import { HealthComponent } from './shared/components/health/health.component';

registerLocaleData(localeEs, 'es');

// ATTENTION!!! required for AOT compilation
export function createTranslateLoader(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    PagesComponent,
    PagesUserComponent,
    LoginComponent,
    FooterComponent,
    HeaderComponent,
    BottomFooterComponent,
    LogoComponent,
    SearchComponent,
    ButtonsComponent,
    HeaderTopComponent,
    NavBarComponent,
    CartButtonComponent,
    CartComponent,
    FooterBannerComponent,
    SnackBarComponent,
    AttClienteComponent,
    FaqComponent,
    VisorComponent,
    LoginCheckoutComponent,
    SnackBarErrorComponent,
    VideoComponent,
    TutorialComponent,
    HealthComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forRoot({
      defaultLanguage: 'es',
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    FontAwesomeModule,
    BrowserAnimationsModule,
    MatSnackBarModule,
    MatMenuModule,
    MatSelectModule,
    NgxExtendedPdfViewerModule,
    MatDialogModule,
    MatExpansionModule,
    PipesModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: LOCALE_ID,
      useValue: 'es',
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
