import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@environment/environment';
import { COMANAI_EXCERPTS } from "@constants/comanai-excerpts";

@Component({
  selector: 'app-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss'],
})
export class LogoComponent implements OnInit {
  logoComanai: string = COMANAI_EXCERPTS.logoComanai;

  constructor(private route: Router) {}

  ngOnInit(): void {}

  goToHome(): void {
    this.route.navigate(['']);
  }
}
