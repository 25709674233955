import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ShareService {
  private idStation = new BehaviorSubject('');
  private idOf = new BehaviorSubject('');
  private user = new BehaviorSubject('');
  private search = new BehaviorSubject('');
  private cartState = new BehaviorSubject('');
  private commercialState = new BehaviorSubject('');
  private categoriesState = new BehaviorSubject('');

  currentIdStation = this.idStation.asObservable();
  currentIdOf = this.idOf.asObservable();
  currentUser = this.user.asObservable();
  currentSearch = this.search.asObservable();
  currentCartState = this.cartState.asObservable();
  currentCommercialState = this.commercialState.asObservable();
  currentCategories = this.categoriesState.asObservable();

  constructor() {}

  changeIdStation(id: string) {
    this.idStation.next(id);
  }
  changeIdOf(id: string) {
    this.idOf.next(id);
  }
  changeCurrentUser(user: any) {
    console.log('shareService', user);
    this.user.next(user);
  }

  changeSearch(id: string) {
    console.log('string', id);
    this.search.next(id);
  }

  changeCartState(id: string) {
    console.log(id);
    this.cartState.next(id);
  }

  changeComercials(data: any) {
    console.log(data);
    this.commercialState.next(data);
  }

  changeCategories(data: any) {
    console.log(data);
    this.categoriesState.next(data);
  }

  doLogOut(){
    this.idStation.next('');
    this.idOf.next('');
    this.user.next('');
    this.search.next('');
    this.cartState.next('');
    this.commercialState.next('');
    this.categoriesState.next('');

  }
}
