import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer-banner',
  templateUrl: './footer-banner.component.html',
  styleUrls: ['./footer-banner.component.scss'],
})
export class FooterBannerComponent implements OnInit {
  list: any[] = [
    {
      img: 'assets/images/cart.png',
      title: 'ASESORAMIENTO',
      subtitle: 'Piezas recomendadas',
    },
    {
      img: 'assets/images/card.png',
      title: '100% SEGURO',
      subtitle: 'Sólo pagos seguros',
    },
    {
      img: 'assets/images/van.png',
      title: 'ENVÍO RÁPIDO',
      subtitle: 'Para todos los pedidos',
    },
    {
      img: 'assets/images/medal.png',
      title: 'Con la GARANTÍA de',
      subtitle: 'COMANAI Smarter Engineering',
    },
  ];
  constructor() {}

  ngOnInit(): void {}
}
