import { Component, OnInit } from '@angular/core';
import { environment } from '@environment/environment';
import {
  faCcApplePay,
  faCcMastercard,
  faCcPaypal,
  faCcStripe,
  faCcVisa,
} from '@fortawesome/free-brands-svg-icons';

@Component({
  selector: 'app-bottom-footer',
  templateUrl: './bottom-footer.component.html',
  styleUrls: ['./bottom-footer.component.scss'],
})
export class BottomFooterComponent implements OnInit {
  faCcVisa = faCcVisa;
  faCcStripe = faCcStripe;
  faCcPaypal = faCcPaypal;
  faCcMastercard = faCcMastercard;
  faCcApplepay = faCcApplePay;

  version = environment.version;

  constructor() {}

  ngOnInit(): void {}
}
