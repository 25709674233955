import { Component, OnInit } from '@angular/core';
import {
  faFacebookF,
  faLinkedinIn,
  faTwitter,
} from '@fortawesome/free-brands-svg-icons';
import { TranslateService } from '@ngx-translate/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header-top',
  templateUrl: './header-top.component.html',
  styleUrls: ['./header-top.component.scss'],
})
export class HeaderTopComponent implements OnInit {
  faFacebook = faFacebookF;
  faTwitter = faTwitter;
  faLinkedin = faLinkedinIn;

  lang: string = 'es';

  constructor(
    private translate: TranslateService,
    private location: Location,
    private router: Router
  ) {
    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang('es');

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    translate.use('es');
    localStorage.setItem('language', 'en');
  }

  ngOnInit(): void {}

  // Change language by click
  useLanguage(lang: string): any {
    const local = this.location.path();
    this.translate.use(lang);
    localStorage.setItem('language', lang);
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([local]);
    });
  }
}
