<div class="container">
  <input
    type="text"
    placeholder="Buscar.."
    (keyup)="applyFilter($event)"
    class="form"
    [(ngModel)]="searchValue"
  />
  <span class="search">
    <fa-icon [icon]="faSearch" class="icon"></fa-icon>
  </span>
</div>
