<div class="header">
 <div class="content logo">
    <a *ngIf="logo; else noLogo" [href]="webAdminComanai" target="_blank" rel="noopener noreferrer">
      <img  src="{{logo | secure | async}}" class="logoImg">
    </a>
    <ng-template #noLogo></ng-template>
  </div>
  <div class="content">
    <a *ngIf="id" [routerLink]="'user/profile'" class="user"><span class="icon-user margin"></span></a>
    <a *ngIf="!id" routerLink="auth" class="user"><span class="icon-user margin"></span></a>

    <app-cart-button class="margin" (click)="goToCart()"></app-cart-button>
  </div>
</div>

