<div *ngIf="!isLogged" class="header"><app-logo></app-logo></div>
<div *ngIf="isLogged" class="header">
  <div class="container-log-seach">
    <app-logo></app-logo>
    <app-search class="container-40"></app-search>
  </div>
  <app-buttons *ngIf="isLogged" class="container-35"></app-buttons>
</div>
<div *ngIf="!isLogged" class="header-movil logo-mov"><app-logo></app-logo></div>
<div *ngIf="isLogged" class="header-movil">
  <div class="flex">
    <div class="flex">
      <fa-icon [icon]="bars" class="icon" [matMenuTriggerFor]="menu"></fa-icon>
      <app-logo></app-logo>
    </div>
    <div><app-buttons *ngIf="isLogged" [user]="user" class="container-35"></app-buttons></div>
  </div>
  <div>
    <app-search class="container-40"></app-search>
  </div>
</div>

<mat-menu #menu="matMenu">
  <div class="menu">
    <div class="super-menu">
    <div class="cross">
      <img src="assets/images/x.svg" alt="close" class="close" />
    </div>
    <div class="idiom">
      <span class="primary">|</span>
      <select name="currency" class="sel">
        <option value="eur">EUR</option>
        <option value="usd">USD</option>
      </select>
      <span class="primary">|</span>
      <select class="sel" name="value" (change)="useLanguage($event.target)">
        <option value="es">ESP</option>
        <option value="en">ENG</option>
      </select>
    </div>
  </div>
    <div class="center">
      <div class="icons">
        <fa-icon
          *ngIf="isLogged"
          [icon]="userLogo"
          class="user"
          (click)="goToLink('user/profile')"
        ></fa-icon>
        <span *ngIf="isLogged" class="barr">|</span>
        <img
          src="assets/images/carrito.svg"
          alt="cart"
          class="cart"
          (click)="goToLink('cart')"
        />
      </div>
      <div class="list">
        <ul *ngIf="!isLogged" class="links">
          <li *ngFor="let link of links" (click)="goToLink(link.link)" [innerHTML]="link.text | translate | uppercase ">
          </li>
        </ul>
        <ul *ngIf="isLogged" class="links">
          <li *ngFor="let link of linksLogged" (click)="goToLink(link.link)" [innerHTML]="link.text | translate  | uppercase ">
          </li>
        </ul>
      </div>
      <div class="list faq">
        <ul class="links">
          <li (click)="goToLink('faq')">FAQ</li>
        </ul>
      </div>
      <div class="list faq">
        <ul class="links">
          <li *ngIf="!isLogged" (click)="goToLink('auth')">
            {{ "Iniciar Sesión" | translate | uppercase }}
          </li>
          <li *ngIf="isLogged" (click)="doLogOut()">
            {{ "Cerrar Sesión" | translate | uppercase }}
          </li>
        </ul>
      </div>
      <ul class="social">
        <li class="no-dist">
          <a class="icon-circle icon-circle-li"
            ><fa-icon [icon]="faLinkedin" class="icon"></fa-icon
          ></a>
        </li>
        <li class="no-dist">
          <a class="icon-circle icon-circle-fa"
            ><fa-icon [icon]="faFacebook" class="icon"></fa-icon
          ></a>
        </li>
        <li class="no-dist">
          <a class="icon-circle icon-circle-yt"
            ><fa-icon [icon]="faYt" class="icon"></fa-icon
          ></a>
        </li>
      </ul>
      <div class="txt">
        {{ "marketplace de recambios industriales" | translate | uppercase }}
      </div>
    </div>
  </div>
</mat-menu>
