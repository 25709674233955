import { HttpClient } from '@angular/common/http';
import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';

@Pipe({
  name: 'secure'
})
export class SecurePipe implements PipeTransform {
  constructor(private http: HttpClient) {}
  transform(url: string) {
    return new Observable<string>((observer) => {
      observer.next(
        'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=='
      );
      const token = localStorage.getItem('access_token');
      this.http
        .get(url, {
          responseType: 'blob',
          headers: { Authorization: token ? 'Bearer ' + token : '' },
        })
        .subscribe((response) => {
          const reader = new FileReader();
          reader.readAsDataURL(response);
          reader.onloadend = function () {
            observer.next((reader as any).result);
          };
        });

      return { unsubscribe() {} };
    });
  }

}
