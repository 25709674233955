import { environment } from 'environments/environment';

export const API_ENDPOINTS = {
  files: `${environment.api}/work-orders/file/`,
  filesLogo: `${environment.api}/clients/logo/`,
  filesLogoOT: `${environment.api}/work-orders/file/picture/`,
  filesComanaiElements: `${environment.api}/comanai-elements/file/documentation/`,
  picturesComanaiElements: `${environment.api}/comanai-elements/file/pictures/`,
  filesCommercialsDocs: `${environment.api}/commercials/file/documentation/`,
  filesCommercialPics: `${environment.api}/commercials/file/pictures/`,
  filesAssemblies: `${environment.api}/assemblies/file/documentation/`,

}
