import { UserService } from '@Services/user/user.service';
import { Component, OnInit } from '@angular/core';
import { faPlay } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-tutorial',
  templateUrl: './tutorial.component.html',
  styleUrls: ['./tutorial.component.scss']
})
export class TutorialComponent implements OnInit {

  faPlay = faPlay;

  constructor(
    private userService: UserService
  ) { }

  ngOnInit(): void {
  }

  showVideo(video:string) {
    this.userService.watchVideo(video)
  }

}
