<div class="flex-center-between ">
  <div><p class="tex-center">{{ 'sign' | translate }}</p> V. {{version}}</div>
  <!-- <div>
    <fa-icon [icon]="faCcVisa" class="icon-foot"></fa-icon>
    <fa-icon [icon]="faCcPaypal"class="icon-foot"></fa-icon>
    <fa-icon [icon]="faCcStripe"class="icon-foot"></fa-icon>
    <fa-icon [icon]="faCcMastercard"class="icon-foot"></fa-icon>
    <fa-icon [icon]="faCcApplepay"class="icon-foot"></fa-icon>
  </div> -->
</div>
