import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss'],
})
export class FaqComponent implements OnInit {
  faqNumber = 38;
  faqs: any[] = [];

  constructor() {}

  async ngOnInit(): Promise<any[]> {
    for (let index = 1; index < this.faqNumber; index++) {
      const element = { title: 'faq' + index, response: 'faqr' + index };
      this.faqs.push(element);
    }
    console.log(this.faqs);
    return this.faqs;
  }
}
