import { Component, Inject } from '@angular/core';
import { MatSnackBarConfig, MatSnackBarRef } from '@angular/material/snack-bar';

@Component({
  selector: 'app-snack-bar-error',
  templateUrl: './snack-bar-error.component.html',
  styleUrls: ['./snack-bar-error.component.scss'],
})
export class SnackBarErrorComponent {
  constructor(
    @Inject(MatSnackBarRef) public data: MatSnackBarConfig,
    public matSnackBar: MatSnackBarRef<MatSnackBarConfig>
  ) {
    console.log('data', this.matSnackBar, this.data.duration);
  }
}
